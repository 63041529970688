/* eslint-disable camelcase */
import { Auth } from 'aws-amplify'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setUser } from './user.reducer'
import { User } from './user.type'

export const parseUserData = (user: any): User => {
  const { attributes } = user
  return {
    email: attributes.email,
    email_verified: attributes.email_verified,
    name: attributes.name,
    sub: attributes.sub,
    preferred_username: attributes.preferred_username,
    identities: JSON.parse(attributes.identities || '{}'),
  }
}

export const useInitUser = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    const getUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true
        })
        dispatch(setUser(parseUserData(user)))
      } catch (error) {
        // non-authenticated user
        console.info('non-authenticated user')
      }

      const preSignInUrl = localStorage.getItem('preSignInUrl')
      if (preSignInUrl) {
        localStorage.removeItem('preSignInUrl')
        navigate(preSignInUrl)
      }
    }
    getUser()
  }, [])
}
