import { Auth } from 'aws-amplify'
import { Button } from 'react-bootstrap'
import { useGlobalModalContext } from 'features/Modal/GlobalModal'

const Login = () => {
  const { showModal, hideModal } = useGlobalModalContext()
  const onLogin = () => {
    showModal('LOADING_MODAL', undefined)
    const currentUrl = window.location.pathname
    localStorage.setItem('preSignInUrl', currentUrl)
    Auth.federatedSignIn().catch((error) => {
      console.error('Login error', error)
      hideModal()
    })
  }

  return (
    <Button
      className='app-button login-button'
      id='button'
      style={{ width: 180 }}
      onClick={onLogin}
    >
      {/* TODO: add translation key */}
      LOGIN
    </Button>
  )
}
export default Login
